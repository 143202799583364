import React from "react"
import GlobalStyle from "../style/global"
import Layout from "../components/layout"
import theme from "../style/theme"
import { SLink, Col2MaxWidth, SCard } from "../style/initial"
import { ThemeProvider } from "styled-components"
import Helmet from "react-helmet"
import { maintenanceMode } from "../constants"
import Maintenance from "./maintenance"

const IndexPage = () => {

  const [ unlocked, setUnlocked ] = React.useState(false)

  if (maintenanceMode && !unlocked) {
    return (<Maintenance unlock={setUnlocked} />)
  }

  return (
    <Layout emailNoCheck={true}>
      <Helmet>
        <title>Pin Point Form Portal</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Col2MaxWidth>
          <SLink to="/industrial-application">
            <SCard style={{ marginBottom: "0" }}>
              <h2>Application Form</h2>
            </SCard>
          </SLink>
          <SLink to="/lear-foam-plant-induction">
            <SCard>
              <h2>
                Lear Induction
                <br />
                (Foam Plant)
              </h2>
            </SCard>
          </SLink>
          <SLink to="/lear-starter-induction">
            <SCard>
              <h2>
                Lear Foam &amp; JIT
                <br />
                Plant New Starter Form
              </h2>
            </SCard>
          </SLink>
          <SLink to="/unipres-form">
            <SCard>
              <h2>Unipres Induction</h2>
            </SCard>
          </SLink>
          <SLink to="/hgv-pack">
            <SCard>
              <h2>HGV Pack</h2>
            </SCard>
          </SLink>
          <SLink to="/light-van-application">
            <SCard>
              <h2>Light Van Application</h2>
            </SCard>
          </SLink>
          <SLink to="/technical-application">
            <SCard>
              <h2>Technical Application</h2>
            </SCard>
          </SLink>
          <SLink to="/permanent-registration">
            <SCard>
              <h2>Permanent Registration</h2>
            </SCard>
          </SLink>
        </Col2MaxWidth>
        <hr />
        <Col2MaxWidth>
        <SLink to="/education-application">
            <SCard>
              <h2>Education Application</h2>
            </SCard>
          </SLink>
        </Col2MaxWidth>
        <hr />
        <Col2MaxWidth>
          <SLink to="/healthcare-company-application">
            <SCard>
              <h2>Healthcare company application</h2>
            </SCard>
          </SLink>
          <SLink to="/nurse-company-application">
            <SCard>
              <h2>Nurse company application</h2>
            </SCard>
          </SLink>
          <SLink to="/healthcare-dbs-information">
            <SCard>
              <h2>Healthcare DBS information</h2>
            </SCard>
          </SLink>
        </Col2MaxWidth>
      </ThemeProvider>
    </Layout>
  )
}

export default IndexPage
